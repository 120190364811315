@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';


@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";



:root {
    --ion-font-family: 'Roboto-Regular';
    font-size: 1rem;
}

#backButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ion-icon {
        font-size: 1.5rem;
    }
}

app-shipping {
    height: 100%;
    flex: 1;
}

.bluecart-button {
    width: 100%;
    margin-top: 1rem;
    background-color: #71c6ff;
    border-radius: 5px;
}

.bluecart-button-active {
    background-color: #0898f8;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    font-size: 0.9rem;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    font-size: 0.9rem;
}

.flexing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background-color: white;
}

.text-align-center {
    text-align: center;
}

.forgot-button {
    justify-content: center;
    align-items: center;
    display: flex;
}

ion-toolbar,
ion-button.submit-button {
    --background: var(--ion-color-all);
    --color: white;
    --background-activated: var(--ion-color-all);
}

ion-toolbar {
    --background: red !important;
    --background-color: red !important;
}

ion-title {
    font-size: 0.95rem !important;
    font-weight: normal !important;
}

.forgot-icon {
    justify-content: center;
    align-items: center;
    display: flex;
}

.paragraph-size {
    font-size: 14px;
}

.buttons-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

ion-item.horizontalLine {
    --inner-border-width: 2px 0 0 0;
    --min-height: 5px !important;
    --padding-start: 0 !important;
}

.horizontal-flex {
    display: flex;
}

.vertical-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-r .list-md:not(.list-inset):not(.list-md-lines-none) .item:last-child {
    --inner-border-width: 0;
    --border-width: 0 0 0px 0;
}

.myClass {
    color: var(--ion-color-medium-tint);
}

.yourClass {
    color: var(--ion-color-primary-tint);
}

.backTransparent {
    --background: transparent !important;
}

.bib-dark {
    background-color: #774935;
    padding: 0 !important;
}

.disabled {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    position: absolute;
    z-index: 5;
    cursor: initial;
}

.disabled-button {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.cursor {
    cursor: pointer;
}

.center {
    text-align: center;
}

.curv {
    border-radius: 5px;
}

.left {
    text-align: left !important;
}

.right {
    text-align: right !important;
}

.white {
    color: white !important;
}

.blue {
    color: rgb(0, 68, 255);
}

.red {
    color: red;
}

.green {
    color: #00cf79;
}

.back01 {
    border: 1px solid rgb(192, 192, 192);
    border-radius: 5px;
    background-color: white;
    padding: 10px;
}

.back02 {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    margin-top: 1.2rem;
}

.back-modal {
    background-color: transparent !important;
    height: 600px;
    width: 86%;
    margin: 100px auto 100% auto;
}

.back000 {
    background: url('assets/image/home.jpg') no-repeat;
    background-position: top !important;
    background-size: 100%;
}

.back000-splash {
    background: url('assets/image/splash.jpg') no-repeat;
    background-position: top !important;
    background-size: 100%;
}

.back000-small {
    background: url('assets/image/home-small.jpg') no-repeat;
    background-position: top !important;
    background-size: 100%;
    padding: 1rem 0;
    height: 1000px;
}

.back000-help {
    background: url('assets/image/h.png') no-repeat;
    background-position: top !important;
    background-size: 100%;
    padding: 1rem 0;
    height: 1000px;
}

.bib-logo2 {
    margin: 0 18px 0 0;
    height: 55px !important;
    width: 105px;
}

.bib-logo3 {
    margin: 0 auto;
    width: 190px !important;
    margin-right: 20px;
    justify-self: right;
}

.bib-logo {
    width: 150px;
    margin-top: 1rem;
}

.bib-logo4 {
    height: 50px;
    margin: 0.2rem;
}

.logo-sx {
    justify-content: center;

    img {
        width: 120px !important;
    }
}

.ion-padding {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
}

.margin-top-big {
    margin-top: 3.2rem;
}

.center {
    text-align: center;
}

.margin-top {
    margin-top: 2.4rem;
}

.margin-top-small {
    margin-top: 1rem;
}

.margin-bot {
    margin-bottom: 2rem;
}

.margin-bot3 {
    margin-bottom: 1.5rem;
}

.padding {
    padding: 1.2rem;
}

.padding-2 {
    padding: 0 .3rem;
    width: 100%;
}

.padding-top {
    padding-top: 1rem;
}

.padding-bot {
    padding-bottom: 1rem;
}

.done {
    font-size: 14px;
    color: rgb(255, 255, 255);
    background-color: #a0fdd6;
    font-weight: bold;
    border-radius: 50%;
    margin: 0;
}

.pending {
    font-size: 17px;
    margin: 0;
}

.small {
    font-size: 0.8rem !important;
    font-weight: 600;
}

.small2 {
    font-size: 0.85rem !important;
    font-weight: 600;
    color: black;
}

.small3 {
    font-size: 0.85rem !important;
    font-weight: 600;
    color: black;
    margin-bottom: 0.35rem;
}

.dark-color {
    color: var(--ion-color-dark);
}

.dark-small {
    font-size: 10px;
}

.map-icons {
    width: 13px;
    margin-right: 10px;
}

.social {
    width: 2rem;
    margin: 7px;
}

.addr {
    font-size: 0.8rem;
    width: 85px;
    color: dark;
}

.icon-spec {
    font-size: 16px;
    color: rgb(128, 127, 127);
}

.sce {
    --background: rgb(223, 226, 226) !important;
    width: 220px;
}

.app-child {
    margin-top: 5rem;
}

.ionlist-style {
    margin: 0.2rem 0.64rem !important;
    border-radius: 5px;
}

.d-trans {
    margin: 0 0.3rem !important;
    background-color: transparent;
}

.ionlist-transparent {
    margin: 0.64rem;
    border-radius: 5px;
    background-color: transparent !important;
}

.edit-icon {
    font-size: 0.9rem;
    color: rgb(90, 89, 89);
}

.required {
    color: red;
    font-weight: 800;
    font-size: 1.1rem;
}

.vertical {
    vertical-align: top;
}

.text-bold {
    font-weight: bold;
    font-size: 13px;
    color: #000;
}

.footer {
    margin: 2px auto;
    background: #573629;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
        font-size: 2.7rem;
    }
}

.layer1 {
    width: 225px;
    position: absolute;
    top: 130px;
    right: 17px;
}

.layer2 {
    width: 40px;
    height: 40px;
    margin: 50px 20px;
}

ion-button {
    font-size: 0.8rem !important;
    font-weight: normal !important;
    padding: 0 2px !important;
    border-radius: 5px;
}

.ion-slide,
.justify {
    display: flex;
    justify-content: center;
    align-items: center;
}

.width100 {
    width: 100%;
}

.width50 {
    width: 65%;
    font-size: 0.9rem;
}

.border-bottom {
    border-bottom: 1px dotted #aaaaaa;
}

.border-bottom-solid {
    border-radius: 7px;
    box-shadow: 1px 1px 3px #b3b3b3;
}

.user-photo {
    border-radius: 50%;
}

.brown-back {
    background-color: #cecece;
    --background: #cecece;
}

ion-title {
    margin-left: 2px !important;
    padding-left: 0 !important;
}

.bord {
    border: 1px solid red;
}

.bord-orange {
    border: 1px solid rgb(229, 219, 170);
    border-radius: 7px;
    padding: .2rem;
}

.bord-grey {
    border: 1px solid grey;
    border-radius: 7px;
    padding: 1rem;
}

.relative {
    position: relative;
}

.vert {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.vv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.horiz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-loc {
    --background: transparent;
    display: inline-block;
    width: 100%;
    margin-top: 0.4rem;

    .icons {
        width: 1.7rem;
        text-align: center;
        background: url(assets/image/line.png) no-repeat;
        background-position: center;
        height: 98px;

        .i1 {
            font-size: 1.6rem;
            margin: 0.29rem auto 1.2rem auto;
        }

        .i2 {
            font-size: 1.2rem;
        }
    }

    .labels {
        height: 98px;
        width: 100%;

        #e0 {
            color: #d3bc8f;
            font-weight: normal;
            font-size: 1rem;
            font-weight: 1;
            height: 42px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 82%;
        }
    }
}

.note-textarea {
    .textarea-wrapper.sc-ion-textarea-md {
        margin: auto 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .native-textarea.sc-ion-textarea-md {
        height: 100%;
        padding: 5px 0px 5px 5px;
    }
}

.e2 {
    background-color: rgb(255, 255, 255);
    color: black;
    font-size: 1rem;
    border-radius: 4px;
    padding: 10px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.orange {
    color: #df3f00 !important;
}

.greyorangex {
    color: #e4bb99 !important;
}

.orangex {
    color: #ff7501 !important;
}

.yel {
    color: #fffb72
}

.center-v {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.bot-menu {
    border-top: 1px solid #d6d6d6;
    background-color: white;

    ion-col {
        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.center-h {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.relative {
    position: relative;
}

.label-title {
    font-weight: bold !important;
}

.top-curve {
    position: absolute;
    top: 0;
    z-index: 100;
}

.rating {
    display: flex;
    margin-top: 0.5rem;
    flex: 1;

    .filled {
        color: gold;
    }

    .empty {
        color: gray;
    }
}

.filled {
    color: gold;
}

.rating2 {
    margin-top: 0.5rem;
    flex: 1;

    .filled {
        color: gold;
    }

    .empty {
        color: gray;
    }
}

.vert-justify {
    display: flex;
    align-self: center;
}

#notif {
    ion-col {
        align-self: center;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    #search {
        ion-icon {
            font-size: 1.5rem;
        }
    }

    #logo {
        img {
            height: 60px;
        }
    }
}

#notif2 {
    align-self: right;
    text-align: right;

    ion-badge {
        position: absolute;
        top: -0.4rem;
        right: 1rem;
        z-index: 1000;
        font-size: 0.8rem;

        &~ion-icon {
            margin-right: 1.5rem;
            font-size: 1.7rem;
        }
    }
}

#notif3 {
    align-self: right;
    text-align: right;

    ion-badge {
        position: relative;
        top: -0.4rem;
        right: 1rem;
        z-index: 1000;
        font-size: 0.8rem;

        &~ion-icon {
            margin-right: 1.5rem;
            font-size: 1.7rem;
        }
    }
}

.vert-center {
    flex: center;
    align-self: center;
}

.ratings {
    display: flex;

    .filled {
        color: gold;
        font-size: 1rem;
    }

    .empty {
        color: gray;
        font-size: 1rem;
    }
}

.highlight {
    color: orangered;
    font-weight: 600;
}

.weight {
    font-weight: 600;
}

.small-font-6 {
    font-size: 0.6rem !important;
}

.small-font {
    font-size: 0.75rem !important;
}

.big-font-green {
    font-size: 1rem;
    color: rgb(0, 255, 0) !important
}

.small-font-green {
    font-size: .85rem;
    color: rgb(0, 255, 0) !important
}

.big-font-blue {
    font-size: 1rem;
    color: #4e9dff !important
}

.small-font-blue {
    font-size: .85rem;
    color: #4e9dff !important
}

.big-font {
    font-size: 2rem;
    font-weight: 700;
    color: orangered
}

.smaller-font {
    font-size: 0.65rem;
    color: rgb(255, 255, 255);
    font-weight: 400;
}

.vert-align {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vert-align-left {
    display: flex;
    align-items: center;
}

.t {
    color: rgb(250, 188, 95);
    font-weight: 1 !important;
}

.const {
    color: rgb(190, 190, 190);
    font-weight: 1 !important;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 6rem;
}

.locations {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
}

.locations-top {
    position: absolute;
    z-index: 100;
    width: 100%;
    background-color: transparent;
    padding: 7px 14px;

    ion-row {
        background-color: rgb(255, 255, 255);
        border-radius: 5px;
        box-shadow: 1px 2px 2px rgb(206, 206, 206);

        ion-col {
            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 10px;
                font-weight: 900;
                color: rgb(59, 59, 59);
                font-size: 0.9rem;
            }
        }
    }
}

.bib-color {
    color: #db6906;
}

.arr0 {
    margin-bottom: 3rem;
}

.arr1 {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.but-sce {
    ion-col {
        text-align: center;

        ion-button {
            text-transform: none;
            font-size: 0.9rem !important;
            height: 40px !important;
        }
    }
}

.centerx {
    margin: 0 1rem;
    text-align: center;

    ion-item {
        margin-bottom: 0.5rem;
        border-radius: 4px;
    }

    ion-row {
        margin-bottom: 0.5rem;

        ion-col {
            // flex: center;
            // align-self: center;
            color: white;

            ion-button {
                text-transform: none;
            }
        }
    }
}

.centerx ion-row.but-sce {
    margin-bottom: 1.5rem;
}

.leftx {
    margin: 0 1rem;
    text-align: left;
    border: 1px solid rgb(129, 91, 48);
    border-radius: 5px;
    padding: 0.5rem;

    ion-row {
        margin-bottom: 0.5rem;
        border-bottom: 1px dotted rgb(129, 91, 48);

        ion-col {
            font-size: 0.8rem;
            color: white;

            ion-button {
                text-transform: none;
            }
        }
    }
}

.leftx-regular {
    margin: 0 1rem;
    padding: 0.5rem;
    color: white;
    font-size: 1rem;
}

.vals {
    color: rgb(226, 189, 144) !important;
    font-size: 0.8rem;
    font-weight: 100;
}

.vals2 {
    color: rgb(226, 189, 144) !important;
}

.vals-bold {
    color: rgb(255, 219, 174) !important;
    font-size: 0.85rem;
    font-weight: 300;
}

.f1 {
    color: rgb(226, 189, 144) !important;
    font-size: 0.75rem;
    font-weight: 100;
}

.f1-bold {
    color: rgb(255, 219, 174) !important;
    font-size: 0.75rem;
    font-weight: 500;
}

.f2 {
    color: rgb(226, 189, 144) !important;
    font-size: 0.95rem;
    font-weight: 100;
}

.f2-bold {
    color: rgb(255, 219, 174) !important;
    font-size: 0.95rem;
    font-weight: 100;
}

.gray {
    color: rgb(172, 170, 170) !important;
}

.small-icon {
    font-size: 0.8rem;
    color: rgb(126, 125, 125);
    vertical-align: middle;
}

.bord-bot {
    border-bottom: 1px solid rgb(150, 149, 149);
}

.user-card {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    margin: 0;
    animation: mymove 1s;
    width: 100%;
    z-index: 1000;
}

@-webkit-keyframes mymove {
    from {
        bottom: -150px;
    }

    to {
        bottom: 0;
    }
}

@keyframes mymove {
    from {
        bottom: -150px;
    }

    to {
        bottom: 0;
    }
}

.round-but {
    text-transform: none;
    font-size: 1.1rem !important;
    height: 45px !important;
    width: 80%;
    max-width: 600px;
}

.pos {
    position: initial;
}

.favorite-loc {
    margin: 0.4rem 1rem;
    color: rgb(10, 10, 10);
    font-size: 0.9rem;
    text-align: left;

    ion-col {
        ion-icon {
            font-size: 1.4rem;
            color: #db6906;
        }
    }
}

.loc {
    padding: 0.4rem 1rem;

    div {
        margin-bottom: 0.2rem;
    }
}

.location1 {
    background-color: rgb(255, 255, 255);
    color: rgb(53, 52, 52);
    font-size: 1rem;
    border-radius: 4px;
    padding: 6px;
    width: 100%;

    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ion-icon {
            color: #db6906;
            font-size: 1.6rem;
            font-weight: 900;
            margin: auto 0.3rem;
        }
    }
}

.ms {
    background-color: transparent;
    margin: 0 auto !important;
    width: 96% !important;

    ion-row {
        ion-col {
            .ms-div {
                display: flex;
                align-items: center;
                background-color: rgb(255, 255, 255);
                width: 100%;
                border-radius: 4px;
                padding: 12px;

                img {
                    width: 1.1rem !important;
                    height: 1.1rem;
                    margin: auto 0.9rem auto 0.4rem;
                }

                div {
                    margin: 0;
                    width: 85% !important;
                    color: rgb(92, 92, 92);
                    font-size: 0.9rem;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.s-input {
    width: 100%;
    border-radius: 4px;
    background-color: white;
    border-radius: 4px;

    ion-searchbar {
        background-color: white;
        height: 4rem;
        width: 96%;
        margin: 0 auto;
    }
}

.brown-light {
    background-color: linear-gradient(#8b8b8b, white) !important;
    --background: linear-gradient(#949494, white) !important;
}

.gray-light {
    background-color: rgb(243, 240, 240) !important;
    --background: rgb(243, 240, 240) !important;
    font-size: 0.85rem !important;
}

.bold {
    font-weight: 600;
    font-size: 1.3rem;
}

.bold-500 {
    font-weight: 500;
}

.bold-600 {
    font-weight: 600;
}

//---------------------------- resto

.loader {
    opacity: 0.3;
    width: 300px;
    margin-top: 2rem;
}

.resto-title {
    margin-left: 1rem;
    font-weight: 600;
    color: black;
}

.resto-sub-title {
    font-weight: 600;
    color: black;
    margin-top: 0;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left-h {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-h {
    display: flex;
    justify-content: center;
    align-items: right;
}

.check-box {
    font-size: 1rem;
}

.resto-cart-cost {
    bottom: 0;
    margin-right: -10px;
    color: white;
    font-size: 0.7rem;
    font-weight: 100;
}

.gradient {
    background-image: linear-gradient(white, #cecece) !important;
    --background: linear-gradient(white, #cecece) !important;
}

.bord-left {
    border-left: 1px solid rgba(172, 126, 89, 0.836);
    padding-left: 3px;
}

.bord-left2 {
    border-left: 1px solid rgba(172, 126, 89, 0.836);
    padding-left: 3px;
}

.div-but {
    background-color: #db6906;
    color: white;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 9px 2px;
    border-radius: 5px;
    text-align: center;
}

.font1 {
    font-size: 1rem;
}

.font1-5 {
    font-size: 1.5rem;
}

.font2 {
    font-size: 2rem;
}

.font2-5 {
    font-size: 2.5rem;
}

.font3 {
    font-size: 3rem;
}

.font4 {
    font-size: 4rem;
}

.fixed-div {
    overflow: auto;
    height: 80%;
    position: fixed;
    top: 10%;
    right: 10%;
    z-index: 1000;
    border: 1px solid #818181;
}

.fixed-div2 {
    overflow: auto;
    position: fixed;
    top: 10%;
    right: 10%;
    z-index: 1000;
    border: 1px solid #818181;
}

.label-fixed.sc-ion-label-md-h {
    min-width: 150px;
    font-size: 1rem;
}

.strong {
    font-weight: 700;
}


.f0-7 {
    font-size: .7rem
}

.f0-8 {
    font-size: .8rem
}

.f0-9 {
    font-size: .9rem
}

.f1-0 {
    font-size: 1rem
}

.f1-1 {
    font-size: 1.1rem
}

.f1-2 {
    font-size: 1.2rem !important;
}

.f1-3 {
    font-size: 1.3rem
}

.f1-4 {
    font-size: 1.4rem
}

.f1-4 {
    font-size: 1.5rem
}

.f1-5 {
    font-size: 1.5rem !important
}

.f1-6 {
    font-size: 1.5rem
}

.f1-7 {
    font-size: 1.5rem
}

.f1-8 {
    font-size: 1.5rem
}

.f1-9 {
    font-size: 1.5rem
}

.f2-0 {
    font-size: 1.5rem
}

.white {
    color: white !important;
}

.white-bg {
    background-color: white !important;
}

.black {
    color: black !important;
}

.orange-dark {
    color: #be4c04 !important;
}

.red {
    color: rgb(187, 14, 14) !important;
}

.grey {
    color: gray !important;
}

.grey-light {
    color: #8c8b8b !important;
}

.grey-light-2 {
    color: #d3d3d3 !important;
}

.strong {
    font-weight: 700;
}

.bg-white {
    background-color: white !important;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
}

.bg-dark {
    background-color: rgb(59, 58, 58) !important;
}

.bg-black {
    background-color: black !important;
}

.bg-white-2 {
    background-color: white !important;
}

.bg-grey {
    background-color: grey !important;
}

.bg-grey-light {
    background-color: rgb(238, 236, 236) !important;
    margin: 0.2rem 0;
    padding: 1rem
}

.bg-orange {
    background-color: rgb(255, 166, 0);
}

.margin-top-1 {
    margin-top: 1rem;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-top-3 {
    margin-top: 3rem;
}

.margin-top-4 {
    margin-top: 4rem;
}

.margin-top-6 {
    margin-top: 3rem;
}

.margin-top-8 {
    margin-top: 8rem;
}

.margin-top-10 {
    margin-top: 10rem;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-bottom-3 {
    margin-bottom: 3rem;
}

.margin-bottom-6 {
    padding-bottom: 6rem;
}

.padding-bottom-2 {
    padding-bottom: 2rem;
}

.padding-bottom-4 {
    padding-bottom: 4rem;
}

.margin-left-2 {
    margin-left: 2rem;
}

.margin-left-4 {
    margin-left: 4rem;
}

.about-chef {
    ion-icon {
        color: grey;
        margin-right: 1rem;
    }
}

.small-but {
    padding: 0.2rem;
    height: 30px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
        margin-right: .3rem;
        color: rgb(94, 48, 5);
    }

    span {
        text-transform: capitalize;
        color: black;
    }
}

.cap {
    text-transform: capitalize !important;
    font-size: .9rem !important;
}

.invalid {
    cursor: not-allowed !important;
}

.img-cover-33 {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    margin-right: 1rem;
}

.img-cover-70 {
    height: 70px;
    border-radius: 50%;
    margin: .4rem;
}

.img-cover-square {
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.form-items {
    ion-item {
        margin-top: 0.6rem;
        background-color: #f1f1f1;
        --background: #f1f1f1;
        border-radius: 6px;
        width: 100% !important;
    }
}

ion-input {
    border-radius: 6px !important;
    background-color: white !important;
    border: 1px solid #ebeaea !important;
    padding-left: 1rem !important;
}

input {
    padding-left: 1rem !important;
}

.div-layer {
    position: fixed;
    z-index: 1000;
    background-color: rgba(128, 128, 128, 0.54);
    height: 100%;
    top: 3.3rem;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .div-containter {
        padding: 0.7rem;
        border-radius: 6px;
        margin: 1rem;
        background-color: white;
        max-width: 900px;
        overflow: auto;
        height: 80%;
        width: 100%;
    }
}

.div-form {
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 6px;
    margin: 1rem auto;
    background-color: white;
    min-width: 650px;
}

.div-form-wide {
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 6px;
    margin: 1rem auto;
    background-color: white;
    min-width: 700px;
}

.prem-cal {
    color: red;
    background-color: rgb(0, 0, 0);
    padding: 1rem;
    width: 80%;
    margin: 1rem auto;
    border-radius: 8px;
}

.prices {
    background-color: #eee;
    padding: 1rem;
    border: 1px solid gray;
    margin: 2rem auto;
    max-width: 500px;
    border-radius: 8px;
}

.price {
    font-size: 1rem;
}


.nutrition {
    text-align: center;
    overflow: hidden;
    max-width: 700px;

    .form-group {
        width: 130px;
        border: 1px dotted grey;
        border-radius: 7px;
        display: inline-block;
        margin: .6rem;
        box-sizing: border-box;
        padding: .4rem;
        background-color: #fffacb;
        font-size: .9rem;
        text-align: center;
    }
}

.input-and-icon {
    position: relative;

    fa-icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0%, -50%);
        color: red;
        display: none;
        cursor: pointer;
    }

    &:hover {
        fa-icon {
            display: block;
        }
    }
}

.tag-button {
    min-width: 130px;
    border: 1px dotted grey;
    border-radius: 10px;
    color: blue;
    padding: .4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: .15rem;
    background-color: white;

    ion-icon {
        font-size: 1.5rem;
        margin-left: 1rem;
    }
}

.section-meal {
    background-color: #848484;
    color: white;
    padding: .5rem;
    margin: .1rem;
}

.opacity-1 {
    opacity: 1 !important;
}

.opacity-0-4 {
    opacity: .4 !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
    right: 16px !important;
    left: unset;
}

.master {
    width: 250px;
    margin: 0 auto;
    border: 1px solid grey;
    background-color: white;
    padding: .5rem;
    border-radius: 7px;
    margin-top: .7rem;
}

.premium {
    background-color: black;
    color: rgb(255, 174, 0);
    padding: .25rem;
    width: 130px;
    font-size: .9rem;
    border-radius: 12px;
    border: 1px solid white;
    text-align: center;
}

.width-perc {
    max-width: 1240px !important;
    margin: 0.4rem auto;
}


.active-vendor-circle {
    color: rgb(194, 103, 0) !important;
}



.div1 {
    width: 100%;
    border-bottom: 1px dotted rgb(95, 95, 95);

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.rating-1 {
    font-size: 1rem;
    color: orange;
    background-color: #ab630a;
    border-radius: 50%;
    padding: .1rem;
    margin: 0 .081rem;
}

.rating-2 {
    font-size: 1rem;
    color: #ddd;
    background-color: #817e79;
    border-radius: 50%;
    padding: .1rem;
    margin: 0 .081rem;
}

.rating-01 {
    font-size: 1rem;
    color: orange;
    border-radius: 50%;
    margin: 0 .081rem;
}

.rating-02 {
    font-size: 1rem;
    color: #817e79;
    border-radius: 50%;
    margin: 0 .081rem;
}

.about-chef {
    ion-icon {
        color: grey;
        margin-right: 1rem;
    }
}

.main-div-0 {
    width: 98%;
    max-width: 1024px;
    background-color: rgb(238, 236, 236);
    border-radius: 15px;
    padding: 2rem;
    margin: 1rem auto;
}

.left-div {
    background-color: white;
    width: 100%;
    box-shadow: 0 0 5px rgb(204 204 204);
    padding: 0;
    border-radius: 7px;
    max-width: 800px;
    margin: 1.7rem auto;
}

.left-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 240px;
    border-radius: 7px;
}

.review {
    border-bottom: 1px dotted grey;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}

.ion-segment {
    border: 1px solid #dad9d9 !important;
    border-radius: 7px;
    background-color: #fffbf2;
    padding: 0 0 .4rem 0;
}

.img-cover {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    margin: 0 1rem 0 0;
    object-fit: cover;
    margin: 0 1rem 0 0;
}

.auto {
    margin-right: auto;
    margin-left: auto;
}

.delivery-status {
    max-width: 600px;
    border: 1px dotted grey;
    border-radius: 10px;
    margin: .3rem auto;
    padding: .2rem;
    background-color: white;
}


.divider {
    border-bottom: 2px solid #f5d032;
}

.padding-1 {
    padding: 1rem
}

.padding-1-5 {
    padding: 1.5rem
}

.radius {
    border-radius: 7px;
}

.text-area {
    max-width: 750px;
    margin: 0 auto;
    max-width: 600px;
    font-size: 1.2rem;
    color: rgb(105, 105, 105);
    font-weight: 700;
    border-radius: 7px;
    padding: .5rem;
    --background: white !important;
    border: 1px solid grey;
    height: 200px;
}

.ii {
    max-width: 750px;
    border: 1px solid orange;
    margin: 1rem;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.invalid {
    background-color: rgb(187, 187, 187);
    cursor: not-allowed !important;
}


.container {
    padding: 0px 20px;
}

.custom-btn {
    background-color: var(--ion-color-tertiary);
    border-radius: 14px;
    font-size: 1rem;
    color: black;
    font-weight: bold;
    width: 60%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
    outline: none;
    margin: .8rem auto;
    max-width: 300px;

    &.invalid-btn {
        opacity: 0.4;
        box-shadow: none;
    }

    &.medium-btn {
        box-shadow: none;
        width: 200px;
        margin-top: 20px;
        background-color: #ffebcc;
    }
}

.btn-link {
    background-color: transparent;
    font-size: .9rem;
    color: #3e94ec;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    max-height: 23px;
}

.btn-link-2 {
    background-color: transparent;
    font-size: .9rem;
    font-weight: 500;
    color: #ff6000;
    text-decoration: underline;
    cursor: pointer;
}

.btn-link-3 {
    background-color: transparent;
    font-size: .7rem;
    font-weight: 500;
    color: #ff6000;
    text-decoration: underline;
    cursor: pointer;
}

.double-arrow {
    width: 25px;
    border: 1px solid rgb(218, 218, 218);
    background-color: white;
    border-radius: 40%;

    img {
        width: 100%;
    }
}

.bord-light {
    border: 1px solid #cbcbcb;
    margin: 1rem .25rem;
    border-radius: 6px;
}

.custom-btn-order {
    background-color: #f0ffbd;
    border-radius: 4px;
    font-size: 0.9rem;
    border: 1px solid #0bff0b;
    color: #2e682e;
    padding: 0.2rem 0.3rem;
}


.order-request {
    border-bottom: 1px solid rgb(230, 230, 230);

    p {
        margin: 0;
    }

    .title {
        font-size: 0.8rem;
        color: rgb(138, 138, 138);
    }

    .value {
        font-size: 0.9rem;
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .disclaimer {
        color: rgb(223, 94, 94);
        font-size: .6rem;
        margin-top: 1rem;
    }
}



//--- modal div
.modalDialog {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    opacity: 1;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
}

.modalDialog:target {
    opacity: 1;
    pointer-events: auto;
}

.modalDialog>div {
    width: 330px;
    position: relative;
    margin: 30% auto;
    padding: 5px 20px 13px 20px;
    border-radius: 10px;
    background: #f3f3f3;
}

.line-height {
    line-height: 1.5rem;
}

.close {
    background: #ff5100;
    color: #FFFFFF;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
}

.close:hover {
    background: #00d9ff;
}


.order-request {
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    margin: 0.15rem 1rem;
    background-color: white;

    p {
        margin: 0;
    }

    .title {
        font-size: .95rem;
        color: rgb(70, 70, 70);
    }

    .value {
        font-size: 0.9rem;
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
            width: 50% !important;
        }
    }
}

.order-request {
    border-bottom: 1px solid rgb(230, 230, 230);

    p {
        margin: 0;
    }

    .title {
        font-size: 0.8rem;
        color: rgb(138, 138, 138);
    }

    .value {
        font-size: 0.9rem;
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .disclaimer {
        color: rgb(223, 94, 94);
        font-size: .6rem;
        margin-top: 1rem;
    }
}


.flex-row-center-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.order-request {
    border-bottom: 1px solid rgb(230, 230, 230);

    p {
        margin: 0;
    }

    .title {
        font-size: 0.8rem;
        color: rgb(138, 138, 138);
    }

    .value {
        font-size: 0.9rem;
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .disclaimer {
        color: rgb(223, 94, 94);
        font-size: .6rem;
        margin-top: 1rem;
    }
}



.cal-div {
    text-align: center;
    overflow: auto;
    height: 400px;
    padding: 1rem;

    >div {
        width: 100px;
        height: 100px;
        display: inline-block;
        border: 1px solid rgb(255, 254, 254);
        border-radius: 5px;
        background-color: white;
        font-size: .85rem !important;
        margin: .6rem;
        padding: .3rem;
        box-sizing: border-box;
        box-shadow: 1px 1px 3px rgb(158, 158, 158);
    }
}

.order-request {
    border: 1px solid rgb(194, 194, 194);
    border-radius: 10px;
    margin: 0.15rem 1rem;
    background-color: white;

    p {
        margin: 0;
    }

    .title {
        font-size: .95rem;
        color: rgb(70, 70, 70);
    }

    .value {
        font-size: 0.9rem;
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
            width: 50% !important;
        }
    }
}

.order-request {
    border-bottom: 1px solid rgb(230, 230, 230);

    p {
        margin: 0;
    }

    .title {
        font-size: 0.8rem;
        color: rgb(138, 138, 138);
    }

    .value {
        font-size: 0.9rem;
    }

    .left-image {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .disclaimer {
        color: rgb(223, 94, 94);
        font-size: .6rem;
        margin-top: 1rem;
    }
}


.urgent {
    background-color: rgb(224, 0, 0);
    color: rgb(255, 255, 255);
    font-size: .85rem;
    padding: .2rem .4rem;
}

.urgent-2 {
    background-color: rgb(49, 59, 65);
    color: rgb(255, 255, 255);
    font-size: .95rem;
    padding: .2rem .4rem;
    height: 40px;
}

.urgent-3 {
    background-color: #3a3a3a;
    color: white;
    font-size: 0.95rem;
    padding: .25rem;
    min-height: 30px;
}

.due-nbr {
    background-color: black;
    color: white;
    font-size: 1rem;
    border-radius: 50%;
    padding: .1rem 0.5rem;
    margin-bottom: 0.4rem;
    border: 3px solid orange;
}

.due-nbr-2 {
    background-color: rgb(34, 33, 33);
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    padding: .3rem;
    max-width: 90%;
    margin: .2rem auto 0 auto
}

.opacity-1 {
    display: none;
}

.opacity-2 {
    opacity: 1;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 10px;
    background-color: rgb(253, 249, 215);
    margin: .2rem 0
}


.flex-row-center-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.show-instr {
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;
    margin: .5rem;
    background-color: #313b41;
    color: rgb(172, 171, 171);
    font-size: .9rem;
    border-radius: 7px;
}

.img-cover-2 {
    position: relative;

    ion-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;

        img {
            object-fit: cover;
            width: 80px;
            opacity: .9;
        }

        input {
            position: relative;
            bottom: 0;
            width: 100%;
            font-size: .8rem;
            border: 0;
            color: #ff5200;
            margin-left: .2rem;
            width: 130px;
            border-radius: 5px;
            height: 40px;
        }
    }
}

.show-instr {
    height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;
    margin: .5rem;
    background-color: #313b41;
    color: rgb(172, 171, 171);
    font-size: .9rem;
    border-radius: 7px;
}

.chart-row {
    background-color: #252728;
    color: #c4c4c4;
    border-bottom: 1px dotted #727272;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.chart-div {
    background-color: #373c40;
    color: #d1d1d1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    font-size: 0.9rem;
    width: 98%;
}

ion-grid ion-row {
    background-color: #f3f3f3;
}

.grey-back {
    background-color: rgb(233, 233, 233);
}

.box {
    height: 50px;
    margin: 0 auto;
}

.earn {
    margin: .3rem
}

.alert-wrapper.sc-ion-alert-md,
.sc-ion-alert-md-h {
    --min-width: 360px !important;
    --min-width: 360px !important;
}

.zoneHub {
    max-width: 360px !important;
    width: 70%;
    background-color: #e1e1e1;
    border-radius: 5px;
    margin: .5rem auto;
    font-weight: bold;
}

.rec {
    border-radius: 5px;
    padding: 0.4rem;
    margin-right: 0.5rem;
    height: 30px;
    min-width: 70px;
    border: 1px solid #a9a9a9;
}

.ag-small-but {
    color: rgb(255, 255, 255);
    padding: 0.2rem !important;
    border: 1px solid rgb(175, 175, 175);
    background-color: rgb(255, 136, 0);
    border-radius: 5px;
    margin: .15rem;
    padding: .2rem;
}

.ag-link {
    color: black !important;
    font-size: .6rem !important;
}

.web {
    margin: 1rem auto;
    max-width: 1500px;
    width: 98%;
    background-color: #eaeff1;
    padding: 0.5rem;
    border-radius: 7px;
}

.font-g {
    font-size: 1rem;
}

@media (max-width: 1024px) {
    .font-g {
        font-size: .85rem !important;
    }

    .label-fixed.sc-ion-label-md-h {
        min-width: 40px;
        font-size: .8rem;
    }

    input,
    textarea {
        font-size: .8rem !important;
    }

    h6 {
        font-size: .9rem !important;
    }

}


//-------------------------flex-------------------------------------

.flex {
    display: flex;
}

.f-row {
    flex-direction: row;
}

.f-col {
    flex-direction: column;
}

.f-just-center {
    justify-content: center;
}

.f-just-start {
    justify-content: flex-start;
}

.f-just-end {
    justify-content: flex-end;
}

.f-just-btw {
    justify-content: space-between;
}

.f-just-around {
    justify-content: space-around;
}

.f-just-evenly {
    justify-content: space-evenly;
}

.f-align-center {
    align-items: center;
}

.f-align-start {
    align-items: flex-start;
}

.f-align-end {
    align-items: flex-end;
}

.f-align-base {
    align-items: baseline;
}

.f-wrap {
    flex-wrap: wrap;
}

.f-nowrap {
    flex-wrap: nowrap;
}

//-----------------------

.flex-dir-col {
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-col-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-rowstart {
    display: flex;
    align-items: center;
    flex-direction: row;
}


.flex-rowcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.flex-rowbtw {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-rowend {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}


.flex-colcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flex-colend {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column !important;
}

.flex-colstart {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column !important;
}

// ------------ scroll 

.scroll {
    overflow-x: auto;
    overflow-y: auto;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 9px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: rgba(25, 95, 136, 0.342);
    border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(114, 176, 247);
}